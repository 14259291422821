import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const TeamCard = ({
  employeeImage,
  showNameAndPosition,
  landscapeFormat,
  isPlaceholderImage,
  name,
  position,
}) => {
  return (
    <div
      className={`relative group overflow-hidden ${
        !employeeImage?.gatsbyImageData ? "bg-slate-600" : ""
      } ${
        landscapeFormat
          ? "col-span-1 md:col-span-1 lg:col-span-2"
          : "col-span-1"
      }`}
    >
      {showNameAndPosition && (
        <div className="flex flex-col justify-center md:opacity-0 absolute top-0 md:group-hover:opacity-100 z-30 h-full w-full p-4 md:ease-in md:duration-100 group-hover:bg-white group-hover:bg-opacity-40 bg-white md:bg-none bg-opacity-30 md:bg-opacity-0">
          <div className="tracking-widest text-xl text-gold text-center uppercase">
            {name}
          </div>
          <div className="text-base tracking-wider text-gold text-center pb-2">
            {position}
          </div>
        </div>
      )}
      {employeeImage && (
        <GatsbyImage
          image={employeeImage?.gatsbyImageData}
          className={`object-cover h-full w-full ${
            !landscapeFormat ? "aspect-w-3 aspect-h-4" : ""
          } ${landscapeFormat ? "aspect-w-16 aspect-h-9" : ""} ${
            !isPlaceholderImage
              ? "group-hover:scale-110 group-hover:opacity-50 ease-in duration-200"
              : ""
          }`}
          alt={employeeImage?.title ? employeeImage?.title : ""}
        />
      )}
    </div>
  )
}

TeamCard.propTypes = {
  employeeImage: PropTypes.object,
  name: PropTypes.string,
  position: PropTypes.string,
  showNameAndPosition: PropTypes.bool,
  landscapeFormat: PropTypes.bool,
  isPlaceholderImage: PropTypes.bool,
}

export default TeamCard
