import React from "react"
import PropTypes from "prop-types"

const FormerEmployeesCard = ({ title, formerEmployees }) => {
  return (
    <div className="relative flex flex-col items-end lg:justify-end col-start-3">
      <div className="text-xl tracking-widest text-gray-600 mb-8 text-right max-w-[60%]">
        {title}
      </div>
      {formerEmployees.map(employee => (
        <div key={employee.id} className="mb-2 text-base text-gray-500 tracking-widest">
          {employee.name}
        </div>
      ))}
    </div>
  )
}

FormerEmployeesCard.propTypes = {
  formerEmployees: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default FormerEmployeesCard
