// Gatsby supports TypeScript natively!
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"
import Grid from "../components/grid"
import TeamCard from "../components/cards/team-card"
import FormerEmployeesCard from "../components/cards/former-employees-card"

const Team = ({
  path,
  data: {
    team: { keyvisual, teamImage, titleFormerEmployees, photographer, employees, formerEmployees },
  },
}) => {

  return (
    <Layout path={path}>
      <Seo title="Team" />
      <Container fullWidth maxWidth5xl>
        {keyvisual && (
          <GatsbyImage
            className="aspect-w-16 aspect-h-7"
            image={keyvisual?.gatsbyImageData}
            alt={keyvisual?.title ? keyvisual?.title : ""}
          />
        )}
      </Container>
      <Container verticalSpaceSm>
        <Grid>
          {employees.length > 0 &&
            employees.map(
              employee =>
                employee && <TeamCard key={employee.id} {...employee} />
            )}
        </Grid>
        <Grid>
          {teamImage && (
            <div className="col-span-3 py-4 md:py-6">
              <GatsbyImage
                image={teamImage?.gatsbyImageData}
                alt={teamImage?.title ? teamImage?.title : ""}
              />
            </div>
          )}
        </Grid>
        {photographer ? (
          <Grid>
            <div className="text-sm tracking-widest">
              Fotografie: {photographer}
            </div>
          </Grid>
        ) : null}
        <Grid>
          {formerEmployees.length > 0 && (
            <FormerEmployeesCard
              title={titleFormerEmployees}
              formerEmployees={formerEmployees}
            />
          )}
        </Grid>
      </Container>
    </Layout>
  )
}

export default Team

export const pageQuery = graphql`
  query TeamQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    team: contentfulTeam(id: { eq: "317652ed-de6a-5d4b-ae1a-4241df0fdb9b" }) {
      titleFormerEmployees
      photographer
      keyvisual {
        gatsbyImageData(
          quality: 40
          placeholder: DOMINANT_COLOR
          layout: FULL_WIDTH
        )
      }
      employees {
        id
        name
        position
        showNameAndPosition
        landscapeFormat
        isPlaceholderImage
        employeeImage {
          gatsbyImageData(
            quality: 40
            placeholder: DOMINANT_COLOR
            layout: FULL_WIDTH
          )
        }
      }
      teamImage {
        gatsbyImageData(
          quality: 40
          placeholder: DOMINANT_COLOR
          layout: FULL_WIDTH
        )
      }
      formerEmployees {
        id
        name
        position
        employeeImage {
          gatsbyImageData(
            quality: 40
            placeholder: DOMINANT_COLOR
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`
